<template>
  <component :is="layout">
    <router-view />
  </component>
</template>
<script>
/* eslint-disable */

import { defineAsyncComponent } from "vue";

const Horizontal = defineAsyncComponent(() =>
  import("@/views/Layouts/Horizontal.vue")
);
import { logEvent } from "firebase/analytics";

const Full = defineAsyncComponent(() => import("@/views/Layouts/Full.vue"));

export default {
  name: "App",
  components: {
    Full,
    Horizontal,
  },
  data() {
    return {};
  },
  methods: {},
  computed: {
    layout() {
      if (
        this.$route.meta.layout === "full" ||
        this.$route.meta.layout === undefined
      ) {
        return "full";
      } else {
        return `horizontal`;
      }
    },
  },
  beforeMount() {
    //this.$store.state.isTransparent = "bg-transparent";
  },
  mounted() {
    document.body.classList.remove("dark-version");
    console.log("Mounted");

    console.log("analytics", this.$analytics);
    if (this.$analytics) {
      logEvent(this.$analytics, "page_view", {
        page_path: window.location.pathname, // The current page path
        page_title: document.title, // The current page title
      });
    } else {
      console.log("Analytics not available!");
    }
  },
};
</script>
<style scoped></style>
