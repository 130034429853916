import { createApp } from "vue";
import { getAnalytics } from "firebase/analytics";

import "maz-ui/css/main.css";
import "vue-select/dist/vue-select.css";
import App from "./App.vue";
import store from "./store";
import keys from "@/store/keys";
import router from "./router";
import API from "@/store/axios";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import PinInput from "v-pin-input";
import ArgonDashboard from "./argon-dashboard";
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";
import LoadScript from "vue-plugin-load-script";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import Toaster from "@meforma/vue-toaster";
import { createVfm } from "vue-final-modal";
const vfm = createVfm();
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { JSEncrypt } from "jsencrypt";
import { Skeletor } from "vue-skeletor";
import ReadMore from "vue-read-more";
import VLazyImage from "v-lazy-image";
import vSelect from "vue-select";
import "./registerServiceWorker";
import VueCleave from "vue-cleave-component";
import Emittery from "emittery";

const appFirebase = firebase.initializeApp({
  apiKey: "AIzaSyD_vYcja0tqGvy3RgrDwD2bqLUxcQNc3v0",
  authDomain: "xtrim-referidos.firebaseapp.com",
  projectId: "xtrim-referidos",
  storageBucket: "xtrim-referidos.appspot.com",
  messagingSenderId: "998319452509",
  appId: "1:998319452509:web:9e0a03187988cefb5f78b4",
  measurementId: "G-T2FN5QH22N",
});

const analytics = getAnalytics(appFirebase);

// logEvent(analytics, "page_view", {
//   page_path: window.location.pathname,
//   page_title: document.title,
// });

const auth = firebase.auth();

auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);

const encryptBackoffice = new JSEncrypt();
encryptBackoffice.setPublicKey(keys.publicKeyBackoffice);

const ua = navigator.userAgent;

let isMobile = false;

if (
  /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
    ua
  )
) {
  //es mobile
  isMobile = true;
} else {
  //es destopk
  isMobile = false;
}

const appInstance = createApp(App);
appInstance.component("MazPhoneNumberInput", MazPhoneNumberInput);
appInstance.config.globalProperties.$appFirebase = appFirebase;

appInstance.config.globalProperties.$analytics = analytics;
appInstance.config.globalProperties.$encryptBackoffice = encryptBackoffice;
appInstance.config.globalProperties.$https = API;
appInstance.config.globalProperties.$isMobile = isMobile;
appInstance.config.globalProperties.$eventBus = new Emittery();
appInstance.use(store);
appInstance.use(router);
appInstance.use(ReadMore);

appInstance.use(VueCleave);
appInstance.use(LoadScript);
appInstance.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyD_vYcja0tqGvy3RgrDwD2bqLUxcQNc3v0",
    libraries: "places",
  },
});

appInstance.component("v-select", vSelect);
appInstance.component("v-lazy-image", VLazyImage);
appInstance.component(Skeletor.name, Skeletor);
appInstance.use(VueSweetalert2);
appInstance.use(Toaster);
appInstance.use(vfm);
appInstance.use(PinInput);

appInstance.use(ArgonDashboard);
appInstance.mount("#app");

export { analytics };

// apiKey: "AIzaSyDmFO0DSIVoDeRLxxVvi5CXkY9AULO3Pcc",
// authDomain: "xtrimproec.com",
// projectId: "tvcable-bebde",
// storageBucket: "tvcable-bebde.appspot.com",
// messagingSenderId: "1041448028178",
// appId: "1:1041448028178:web:f3c44c6f2b01ba286530cb",
// measurementId: "G-1QPEL9P6E2"

//maps key: "AIzaSyDAlcyvEoB_DzBgF4JMqr88HZViABFr8nY",

// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyD_vYcja0tqGvy3RgrDwD2bqLUxcQNc3v0",
//   authDomain: "xtrim-referidos.firebaseapp.com",
//   projectId: "xtrim-referidos",
//   storageBucket: "xtrim-referidos.appspot.com",
//   messagingSenderId: "998319452509",
//   appId: "1:998319452509:web:9e0a03187988cefb5f78b4",
//   measurementId: "G-T2FN5QH22N"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
